<template>
  <div>
    <div class="d-flex mx-auto" :class="isMobSmallScreen ? 'w-100' : 'w-75'">
      <div class="w-100 d-flex p-3">
        <div class="w-100">
          <div v-if="message.type == 'standard'" class="d-flex">
            <router-link :to="posterProfileLink">
              <strong class="clickable text-black">{{ message.user.username }} </strong>
            </router-link>
            <small class="ml-auto">{{ formatDescDateTime(message.created) }}</small>
          </div>
          <div class="mt-2 px-0">
            <span v-if="message.type == 'welcome'" class="mr-1">🎉</span
            ><span v-html="sanitizeHtml(messageText.replace(/(?:\r\n|\r|\n)/g, '<br>'))"></span>
          </div>
          <div v-if="message.type == 'welcome'" class="d-flex">
            <small class="ml-auto">{{ formatDescDateTime(message.created) }}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { formatDescDateTime } from '@/shared/utils/dateTime';
import { sanitizeHtml } from '@/shared/utils/html';

const props = defineProps({
  message: {
    type: Object,
    required: true,
  },
});

const message = toRef(props, 'message');

const windowWidth = computed(() => {
  const { width } = useWindowSize();
  return width.value;
});

const isMobSmallScreen = computed(() => {
  return windowWidth.value <= 420;
});

const posterProfileLink = computed(() => {
  return !message.value.character
    ? { name: 'profile', params: { username: message.value.user.username } }
    : {
        name: 'character-profile-new',
        params: { slug: message.value.character.slug },
      };
});

const messageText = computed(() => {
  if (message.value.type === 'standard') {
    return message.value.text;
  } else {
    return message.value.text.replace(message.value.user.username, `<strong>${message.value.user.username}</strong>`);
  }
});
</script>

<style lang="sass" scoped>
.message-poster-img
  width: 60px
  height: 60px
  border-radius: 40px
  border: solid gray 0.1px
  object-fit: cover
</style>
