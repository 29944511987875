<template>
  <ion-page class="page scrollable no-padding">
    <div class="w-100 d-flex flex-column align-items-center wrapper container">
      <div class="cover" :style="{ 'background-image': `url(${cover})` }" style="opacity: 0.5" />
      <div class="mb-2 top d-flex align-items-center">
        <div v-if="roomDetails.world">
          <router-link :to="{ name: 'world-details', params: { slug: roomDetails.world.slug } }" class="text-black">
            <i class="ti-angle-left back-btn clickable-item-hov" />
          </router-link>
        </div>

        <div class="ml-3">
          <h1 class="my-0">
            <strong>{{ roomTitle }} </strong>
          </h1>
        </div>
        <div v-if="get(memberStatus, 'role', '') === worldMemberRoles.leader" class="ml-3">
          <a href="#" class="clickable-item-hov" @click="roomDetailEdit">Edit</a>
        </div>
      </div>

      <div v-html="sanitizeHtml(roomDescription)" class="room-desc px-5"></div>
    </div>

    <ion-content no-bounce class="messages-content px-2 mt-3">
      <ion-refresher mode="md" slot="fixed" @ionRefresh="handleRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <div v-if="isLoading || isRoomLoading" class="d-flex justify-content-center my-4">
        <ChLoading size="sm" class="spinner" />
      </div>
      <div v-if="chatMessages && chatMessages.length && !isRoomLoading">
        <p v-if="nextPageExists && !isLoading" class="clickable-item-hov text-center" @click="requestLoadMore">
          Load More
        </p>

        <div v-for="message of chatMessages" :key="message.id">
          <div :id="`message-${message.id}`">
            <world-chat-msg-card-item v-if="message.character" :message="message"></world-chat-msg-card-item>
            <world-chat-narr-msg v-else :message="message"></world-chat-narr-msg>
          </div>
        </div>
      </div>

      <div v-else class="my-4">
        <div class="mb-2">No Messages Yet.</div>
        <div>Be the one to start the conversation!</div>
      </div>
    </ion-content>

    <div v-if="get(memberStatus, 'status', '') === worldMemberRoles.member" class="mt-2">
      <world-chat-message></world-chat-message>
    </div>
    <div v-else-if="get(memberStatus, 'status', '') === 'guest'" class="mt-2">
      <transition>
        <ion-card class="mx-auto join_card my-3 w-100">
          <div class="join mt-2 text-center text-white">Want to join the conversation?</div>
          <div class="mt-2 mb-2 d-flex justify-content-center">
            <ion-button
              class="clickable-item-hov"
              :disabled="joinStatus.status === 'requested' || memberStatus.status === 'requested'"
              color="light"
              @click="joinWorld"
              >Request to join world</ion-button
            >
          </div>
        </ion-card>
      </transition>
    </div>
    <div v-else></div>

    <join-world-modal :is-open="isFormOpen" :world-id="worldId" @dismiss-modal="closeForm" @status="joinstatus" />

    <world-chat-details-modal
      :is-open="isChatDetailsOpenForm"
      :is-edit="true"
      :world-id="roomDetails.world"
      :room-id="roomDetails.id"
      @dismiss-modal="closeChatDetailsForm"
    ></world-chat-details-modal>
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Action } from 's-vuex-class';

import WorldChatMessage from './WorldChatMessage.vue';
import WorldChatNarrMsg from './WorldChatNarrMsg.vue';
import WorldChatMsgCardItem from './WorldChatMsgCardItem.vue';
import namespace from '@/shared/store/namespace';
import WorldChatDetailsModal from '@/shared/modals/WorldChatDetailsModal.vue';
import { toast } from '@/shared/native';
import { WorldChatDetails } from '@/shared/types/static-types';
import {
  getWorldchatRoomDetails,
  getWorldRoomChatMsgs,
  markWorldRoomMsgsRead,
  getMemberstatus,
} from '@/shared/actions/worlds';
import constants from '@/shared/statics/constants';
import { authStore } from '@/shared/pinia-store/auth';
import { truncateText } from '@/shared/utils/string';
import { sanitizeHtml } from '@/shared/utils/html';

@Options({
  name: 'WorldChatRoom',
  components: {
    WorldChatMessage,
    WorldChatNarrMsg,
    WorldChatMsgCardItem,
    WorldChatDetailsModal,
  },
})
export default class WorldChatRoom extends Vue {
  public isLoading = true;

  sanitizeHtml = sanitizeHtml;

  @Action('getUserCharacters', { namespace: namespace.CharactersModule })
  public getUserCharacters!: any;

  @Action('getUnreadWorldsMsgs', { namespace: namespace.WorldsModule })
  public getUnreadWorldsMsgs!: any;

  public get user() {
    const { user } = authStore();
    return user.value;
  }

  public get isAuthenticated() {
    const { isAuthenticated } = authStore();
    return isAuthenticated.value;
  }

  public isRoomLoading = true;
  public isChatDetailsOpenForm = false;
  wsConnection: WebSocket | null = null;
  chatMessages: {}[] = [];
  paging = { next: null, previous: null, count: 0 };
  page = 1;
  public roomDetails: WorldChatDetails = {};
  public memberStatus: any = {};
  public isFormOpen = false;
  public joinStatus = '';
  worldId = '';

  public worldMemberRoles = constants.worldMemberRoles;

  get = get;

  public get authToken() {
    const { authToken } = authStore();
    return authToken.value;
  }

  public get windowWidth() {
    const { width } = useWindowSize();
    return width.value;
  }

  public get cover() {
    if (!this.roomDetails || !this.roomDetails.banner_img_url) return '/world-spot-placeholder.png';
    return this.roomDetails.banner_img_url;
  }

  public get roomTitle() {
    const descLength = this.windowWidth <= 500 ? 30 : 50;
    return truncateText(get(this.roomDetails, 'title', ''), descLength).replace(/(?:\r\n|\r|\n)/g, '<br>');
  }

  public get roomDescription() {
    const descLength = this.windowWidth <= 500 ? 120 : 200;
    return truncateText(get(this.roomDetails, 'description', ''), descLength).replace(/(?:\r\n|\r|\n)/g, '<br>');
  }

  public joinWorld() {
    this.isFormOpen = true;
  }

  public async closeForm() {
    this.isFormOpen = false;
    this.$nextTick(() => this.fetchMemberStatus());
  }

  public joinstatus(status: string) {
    this.joinStatus = status;
  }

  public async fetchWorldRoomMessages(page = 1, pageSize = 50) {
    const router = useRouter();
    this.isLoading = true;
    let latestMsg: {} | undefined;
    try {
      const { results, ...paging } = await getWorldRoomChatMsgs(router.currentRoute.value.params.id, page, pageSize);

      const reverseResults = orderBy(results, ['created'], ['asc']);

      latestMsg = last(reverseResults);

      this.chatMessages = [...reverseResults, ...this.chatMessages];
      this.paging = paging;
      this.page = page;
    } catch (_err) {}
    this.isLoading = false;

    if (this.page === 1 && latestMsg) {
      this.$nextTick(() => {
        document
          .getElementById(`message-${get(latestMsg, 'id')}`)
          ?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
      });
      await markWorldRoomMsgsRead(router.currentRoute.value.params.id as string);
      this.getUnreadWorldsMsgs();
    }
  }

  public roomDetailEdit() {
    this.isChatDetailsOpenForm = true;
  }

  public async fetchWorldchatRoomDetails() {
    this.isRoomLoading = true;

    try {
      const router = useRouter();
      const resp = await getWorldchatRoomDetails(router.currentRoute.value.params.id as string);
      this.roomDetails = resp;
      this.worldId = this.roomDetails.world.id;
    } catch (_err) {
      toast.show("Couldn't find the World Details.", 'nonative', 'danger');
    }
    this.isRoomLoading = false;
  }

  public get nextPageExists() {
    return !!this.paging.next;
  }

  public async requestLoadMore(ev: CustomEvent) {
    if (!this.paging.next) {
      (ev?.target as any).complete();
    } else {
      await this.fetchWorldRoomMessages(this.page + 1);
    }
  }

  public closeChatDetailsForm() {
    this.isChatDetailsOpenForm = false;
    this.$nextTick(() => this.fetchWorldchatRoomDetails());
  }

  public hookWorldchatRoomWS() {
    const router = useRouter();

    const {
      public: { wsUrl },
    } = useRuntimeConfig();

    const { wsConnection } = useSocket(
      `${wsUrl}/ws/worldchats/room/${router.currentRoute.value.params.id}/?token=${this.authToken}`
    );
    wsConnection.value.onmessage = (event) => {
      const data = JSON.parse(event.data).message;

      if (data.action === 'create') {
        const document = useDocument();
        this.chatMessages.push(data.message);

        this.$nextTick(() => {
          document.value
            ?.getElementById(`message-${get(data.message, 'id')}`)
            ?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
        });
      }
    };
  }

  public async fetchMemberStatus() {
    if (this.isAuthenticated) {
      this.memberStatus = await getMemberstatus({ world_id: this.roomDetails.world.id });
    }
  }

  async mounted() {
    await this.fetchWorldchatRoomDetails();
    if (this.isAuthenticated) {
      this.getUserCharacters({
        id: this.user.id,
        includeUnlisted:
          this.roomDetails?.privacy === 'private' || ['M', 'U'].includes(this.roomDetails?.world?.privacy),
      });
    }
    this.fetchMemberStatus();
    this.fetchWorldRoomMessages();
    this.hookWorldchatRoomWS();
  }
}
</script>

<style scoped lang="sass">
.back-btn
  width: 30px
  height: 30px

.cover
  height: 200px
  width: 100%
  background-position: center
  background-size: cover
  background-repeat: no-repeat
  background-blend-mode: lighten

.top
  position: absolute
  top: 10px

.room-desc
  position: absolute
  top: 70px

.join
  font-weight: bold
  font-size: 18px

.unread-chat
  font-weight: bold
.messages-content
  height: calc(100vh - 465px)
  overflow: auto
.spinner
  width: 40px
  height: 40px
.back-btn
  width: 30px
  height: 30px
.title-input
  max-width: 350px
  @media(min-width: 500px)
    width: 350px

.join_card
  background-color: #3dc2ff
</style>
