<template>
  <div>
    <div class="d-flex" :class="!isParent ? 'w-75 ml-auto' : ''">
      <img
        v-image
        class="comment-poster-img"
        :src="
          resizeUpload(
            get(lastCommentAs, 'type') === commentedAsTypes.USER
              ? '/images/user.jpg'
              : get(lastCommentAs, 'item.info.cropProfilePicture', '/empty.png'),
            '80x80'
          )
        "
      />
      <div class="w-100 ml-2">
        <form @submit.prevent>
          <ion-textarea
            id="msgText"
            v-model="msgText"
            rows="3"
            class="c-textarea m-0"
            :placeholder="`Type message here...`"
            :disabled="isPosting || !isAuthenticated"
            required
          />

          <div class="mt-2 d-flex justify-content-between">
            <div v-if="isAuthenticated" class="text-gray mt-1">
              As:
              <a href="#" @click.prevent="openCommentAsList">{{ lastCommentAsName }}</a>
            </div>
            <i v-else class="text-gray">Log in to send messages</i>

            <ion-button
              class="publish-btn"
              :disabled="isPosting || !isAuthenticated || !msgText"
              :color="postSuccess ? 'success' : 'primary'"
              @click.prevent="onPost"
            >
              <i v-if="postSuccess" class="ti-check-box" />
              <ChLoading size="sm" v-else-if="isPosting" class="spinner" />
              <span v-else>Send</span>
            </ion-button>
          </div>
        </form>
      </div>
    </div>

    <comment-as-select-modal
      :is-open="openCommentAsModal"
      modal-title="Message As"
      yourself-text="Message as yourself/narrator"
      @dismiss-modal="closeCommentAsModal"
      @item-selected="selectCommentAs"
    />
  </div>
</template>

<script lang="ts" setup>
import { toast } from '@/shared/native';
import CommentAsSelectModal from '@/shared/modals/CommentAsSelectModal.vue';
import constants from '@/shared/statics/constants';
import { sendWorldchatMessage } from '@/shared/actions/worlds';
import { authStore } from '@/shared/pinia-store/auth';
import { resizeUpload } from '@/shared/utils/upload';

const props = defineProps({
  isParent: {
    type: Boolean,
    default: true
  },
  parentCommentId: {
    type: String,
    required: false
  }
});
const isParent = toRef(props, 'isParent');
const emit = defineEmits(['posted']);
const commentedAsTypes = constants.commentedAsTypes;

const msgText = ref('');
const isPosting = ref(false);
const postSuccess = ref(false);

const openCommentAsModal = ref(false);

const user = computed(() => {
  const { user } = authStore();
  return user.value;
});

const lastCommentAs = computed(() => {
  const { lastCommentAs } = authStore();
  return lastCommentAs.value;
});

const lastCommentAsName = computed(() => {
  const { lastCommentAsName } = authStore();
  return lastCommentAsName.value;
});

const isAuthenticated = computed(() => {
  const { isAuthenticated } = authStore();
  return isAuthenticated.value;
});

const openCommentAsList = () => {
  openCommentAsModal.value = true;
};

const closeCommentAsModal = () => {
  openCommentAsModal.value = false;
};

const selectCommentAs = (data: { id: string; type: string; item: {} }) => {
  const { updateLastCommentAs } = authStore();
  updateLastCommentAs({
    lastCommentAs: data,
    lastCommentAsName:
      data.type === constants.commentedAsTypes.USER
        ? get(data.item, 'username', 'Unknown')
        : get(data.item, 'info.name', 'Unknown'),
  });
};

const onPost = async () => {
  const router = useRouter();
  isPosting.value = true;
  try {
    const data = {
      text: msgText.value,
      ...(lastCommentAs.value!.type === 'character' && {
        character: lastCommentAs.value!.id,
      }),
    };

    await sendWorldchatMessage(router.currentRoute.value.params.id as string, data);
  } catch (_e) {
    toast.show('Cannot send message. Please try again.', 'nonative', 'danger');
  }
  msgText.value = '';
  isPosting.value = false;
  emit('posted');
};

onMounted(async () => {
  const { updateLastCommentAs } = authStore();
  if (!lastCommentAs.value) {
    updateLastCommentAs({
      lastCommentAs: {
        id: user.value.id,
        type: constants.commentedAsTypes.USER,
        item: user.value,
      },
      lastCommentAsName: user.value.username,
    });
  }
});
</script>

<style lang="sass" scoped>
.comment-poster-img
  width: 60px
  height: 60px
  border-radius: 40px
  border: solid gray 0.1px
  object-fit: cover

.publish-btn
  width: 100px
  .spinner
    width: 15px
    height: 15px
</style>
