<template>
  <div>
    <div class="d-flex">
      <router-link
        v-if="user.username !== message.user.username"
        :to="posterProfileLink"
        class="mr-2 px-1"
        :class="{ disabled: linkDisabled }"
      >
        <img
          v-image
          class="message-poster-img clickable"
          :src="
            !message.character
              ? '/images/user.jpg'
              : resizeUpload(get(message.character, 'info.cropProfilePicture', '/empty.png'), '80x80')
          "
        />
      </router-link>

      <div class="card mb-3 msg-text" @mouseover="hover = true" @mouseleave="hover = false">
        <div class="card-body d-flex p-3 bg-light">
          <div class="w-100">
            <div class="d-flex">
              <router-link :to="posterProfileLink" :class="{ disabled: linkDisabled }">
                <strong class="clickable text-black"
                  >{{ !message.character ? message.user.username : get(message.character, 'info.name', 'Unknown') }}
                </strong>
              </router-link>

              <small class="ml-auto">{{ formatDescDateTime(message.created) }}</small>
            </div>
            <div class="mt-2 px-0">
              <span class="msg-inner">{{ message.text }}</span>
            </div>
          </div>
        </div>
      </div>

      <router-link
        v-if="user.username === message.user.username"
        :to="posterProfileLink"
        :class="{ disabled: linkDisabled }"
        class="ml-2 img-link"
      >
        <img
          v-image
          class="message-poster-img clickable"
          :src="
            !message.character
              ? '/images/user.jpg'
              : resizeUpload(get(message.character, 'info.cropProfilePicture', '/empty.png'), '80x80')
          "
        />
      </router-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { formatDescDateTime } from '@/shared/utils/dateTime';
import { authStore } from '@/shared/pinia-store/auth';
import { resizeUpload } from '@/shared/utils/upload';

const props = defineProps({
  message: {
    type: Object,
    required: true,
  },
});

const message = toRef(props, 'message');
const hover = ref(false);

const user = computed(() => {
  const { user } = authStore();
  return user.value;
});

const linkDisabled = computed(() => {
  if (message.value.character?.slug === '-') return true;
  return false;
});

const posterProfileLink = computed(() => {
  return !message.value.character
    ? { name: 'profile', params: { username: message.value.user.username } }
    : {
        name: 'character-profile-new',
        params: { slug: message.value.character.slug },
      };
});
</script>

<style lang="sass" scoped>
.msg-text
  width: calc(100% - 70px)
.message-poster-img
  width: 60px
  height: 60px
  border-radius: 40px
  border: solid gray 0.1px
  object-fit: cover
.msg-inner
  white-space: pre-line
.disabled
  opacity: 0.75
  pointer-events: none
</style>
