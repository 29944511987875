<template>
  <ion-modal :is-open="isOpen" mode="md" :backdrop-dismiss="true" :show-backdrop="true" @didDismiss="dismissModal">
    <modals-layout :data="{ title: modalTitle }" @dismiss-modal="dismissModal">
      <div class="h-100" style="overflow: auto">
        <form @submit.prevent>
          <div class="mb-3"><i> Please fill out the below form !</i></div>

          <div class="d-flex justify-content-center align-items-center mt-2">
            <div class="mt-3">
              <img
                v-if="chatRoom.banner_img_url"
                class="image mx-2"
                :src="resizeUpload(chatRoom.banner_img_url, '190h')"
              />
              <img loading="lazy" v-else class="image mx-2" src="/empty.png" />

              <UploadForm class="mx-3" @uploaded="changeImage" />
            </div>

            <div class="h-50 w-75">
              <ion-input v-model="chatRoom.title" class="c-input" placeholder="Name of Place*" maxlength="50" />
              <ion-textarea
                v-model="chatRoom.description"
                rows="3"
                class="c-textarea"
                placeholder="Write a description about your place here."
                maxlength="150"
              />
            </div>
          </div>

          <div class="w-100 d-flex justify-content-center mt-4">
            <ion-button class="submit-btn" :disabled="isSubmitting || !isFormComplete" type="submit" @click="onSubmit">
              <ChLoading size="sm" v-if="isSubmitting" class="spinner" />
              <span v-else>{{ actionName }}</span>
            </ion-button>
          </div>
        </form>
      </div>
    </modals-layout>
  </ion-modal>
</template>

<script lang="ts">
import { Options, prop, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 's-vuex-class';
import { authStore } from '@/shared/pinia-store/auth';
import { toast } from '@/shared/native';
import { createWorldChatRoom, editWorldRoomDetails, getWorldchatRoomDetails } from '@/shared/actions/worlds';
import namespace from '@/shared/store/namespace';
import UploadForm from '@/shared/components/upload-form.vue';
import { resizeUpload } from '@/shared/utils/upload';

@Options({
  name: 'JoinWorldModal',
  components: { UploadForm },
})
export default class WorldChatDetailsModal extends Vue {
  @Prop({ default: () => 'Place of Interest', required: false }) modalTitle!: string;
  @Prop({ default: false }) isOpen!: boolean;
  @Prop({ required: true }) worldId!: string;
  @Prop() isPublic!: boolean;
  @Prop({ default: false }) isEdit!: string;
  @Prop() roomId!: string;
  resizeUpload = resizeUpload;
  public get user() {
    const { user } = authStore();
    return user.value;
  }

  public status: any = {};
  @Action('createWorldRequest', { namespace: namespace.WorldsModule })
  public createWorldRequest!: any;

  @Watch('isOpen')
  async editRoom() {
    if (this.isOpen && this.isEdit) {
      const response = await getWorldchatRoomDetails(this.roomId);
      this.chatRoom = response;
    }
  }

  isSubmitting = false;
  chatRoom = {
    title: '',
    description: '',
    banner_img_url: '',
    privacy_pub: 'public',
    privacy_pri: 'private',
  };

  public get isFormComplete() {
    return !!this.chatRoom.title;
  }

  public dismissModal() {
    this.$emit('dismissModal');
    this.chatRoom.title = '';
    this.chatRoom.description = '';
    this.chatRoom.banner_img_url = '';
  }

  public changeImage(image: string) {
    if (typeof image !== 'string') return;
    this.chatRoom.banner_img_url = image;
  }

  public async onSubmit() {
    if (!this.isFormComplete) return;

    try {
      this.isSubmitting = true;

      if (!this.isEdit) {
        await createWorldChatRoom({
          title: this.chatRoom.title,
          description: this.chatRoom.description,
          banner_img_url: this.chatRoom.banner_img_url,
          privacy: this.isPublic ? this.chatRoom.privacy_pub : this.chatRoom.privacy_pri,
          world: this.worldId,
        });

        await toast.show('Place created sucessfully', 'nonative', 'success');
      } else {
        const payload = {
          title: this.chatRoom.title,
          description: this.chatRoom.description,
          banner_img_url: this.chatRoom.banner_img_url,
        };

        await editWorldRoomDetails(this.roomId, payload);
        await toast.show('Place saved sucessfully', 'nonative', 'success');
      }
    } catch (_err) {
      await toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
      this.dismissModal();
    }
    this.dismissModal();
    this.isSubmitting = false;
  }

  public get actionName() {
    return this.isEdit ? 'Save' : 'Add';
  }
}
</script>

<style lang="sass" scoped>
.submit-btn
  width: 180px
  .spinner
    width: 15px
    height: 15px

.image
  width: 110px
  height: 90px
  object-fit: cover
</style>
